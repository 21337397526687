import React from "react"

import Layout from "../../components/UI/Layout"
import Navbar from "../../components/UI/Navbar"
import Seo from "../../components/Seo"
import TestimonialsPageComponent from '../../components/TestimonialsPageComponent'
import CallToAction from '../../components/CallToAction'

export default function TestimonialsPage() {
  return (
    <Layout>
      <Navbar />
      <Seo
        title="Customer Testimonials"
        description="Read testimonials from happy customers that used Andreatta Waterscape to enhance their outdoor living spaces."
      />
      <TestimonialsPageComponent />
      <CallToAction end={true} />
    </Layout>
  )
}
