import React from "react"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery, Link, navigate } from "gatsby"
import slugify from "slugify"

import { makeStyles } from "@material-ui/core/styles"
// import { makeStyles, useTheme } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import Divider from "@material-ui/core/Divider"
// import useMediaQuery from "@material-ui/core/useMediaQuery"
import Container from '@material-ui/core/Container'

const query = graphql`
  {
    allContentfulCustomerTestimonialPage {
      nodes {
        id
        title
        url
        shortDescription
        separatePage
        projectImage {
          gatsbyImageData(
            layout: CONSTRAINED
            width: 500
            placeholder: TRACED_SVG
          )
        }
      }
    }
  }
`

const useStyles = makeStyles(theme => ({
  ctr: {
    paddingTop: "7.25rem",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "95%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  h1: {
    fontSize: "2.9375rem",
    fontWeight: 400,
    // color: theme.palette.common.muted,
    color: theme.palette.secondary.light,
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.25rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "2rem",
    },
  },
  hr: {
    maxWidth: "25%",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "4.5rem",
    borderTopWidth: 2,
    borderBottomWidth: 2,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderStyle: "solid",
    borderColor: theme.palette.secondary.main,
    [theme.breakpoints.down("md")]: {
      marginBottom: "2rem",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "1rem",
    },
  },
  projectImageCtr: {
    maxHeight: 800,
    // borderRadius: 15,
    paddingTop: "4em",
    boxShadow: "0 12px 64px 0 rgb(0 0 0 / 13%)",
    transition: "0.5s",
    marginBottom: "4.75rem",
    marginLeft: "2em",
    objectFit: "contain",
    cursor: "pointer",
    "&:hover": {
      boxShadow: "0 24px 32px 0 rgb(0 0 0 / 16%)",
      transform: "translateY(-5px)",
    },
    "& img": {
      maxWidth: 1000,
      maxHeight: 750,
      // borderRadius: 15,
      cursor: "pointer",
      objectFit: "contain",
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: "2rem",
      marginTop: "3.5em",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  altProjectImageCtr: {
    maxHeight: 800,
    // borderRadius: 15,
    paddingTop: "4em",
    boxShadow: "0 12px 64px 0 rgb(0 0 0 / 13%)",
    transition: "0.5s",
    marginBottom: "4.75rem",
    marginRight: "2em",
    objectFit: "contain",
    cursor: "pointer",
    "&:hover": {
      boxShadow: "0 24px 32px 0 rgb(0 0 0 / 16%)",
      transform: "translateY(-5px)",
    },
    "& img": {
      // borderRadius: 15,
      cursor: "pointer",
      objectFit: "contain",
      maxWidth: 1000,
      maxHeight: 750,
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: "2rem",
      marginTop: "5.5em",
      marginLeft: "auto",
      marginRight: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  projectTitle: {
    fontSize: "2rem",
    fontFamily: "Open Sans, Work Sans, sans-serif",
    // color: theme.palette.common.muted,
    fontWeight: 500,
    paddingLeft: "1.75rem",
    textIndent: "-1.75rem",
    color: theme.palette.text.primary,
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.35rem",
      textIndent: 0,
      paddingLeft: 0,
      float: "none",
    },
    "&a:visited": {
      color: theme.palette.text.primary,
    },
    "&:before": {
      content: "open-quote",
      fontWeight: "bold",
      fontSize: "4rem",
      padding: "0rem 1rem",
      color: theme.palette.secondary.light,
      textDecoration: "none",
      display: "inline-block",
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    "&:after": {
      content: "close-quote",
      fontWeight: "bold",
      fontSize: "4rem",
      padding: "0rem 2.75rem",
      color: theme.palette.secondary.light,
      textDecoration: "none",
      display: "inline-block",
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
  },
  projectTxtCtr: {
    paddingLeft: "3.75rem",
    [theme.breakpoints.down("lg")]: {
      paddingLeft: "3rem",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0rem",
    },
  },
  projectDescription: {
    [theme.breakpoints.down("md")]: {
      padding: "0 6rem",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0 1.5rem",
    },
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  testimonialNameLeft: {
    color: theme.palette.secondary.light,
    fontWeight: 500,
    fontSize: "1.5rem",
    marginLeft: "2rem",
    paddingTop: ".5rem",
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
    },
  },
  testimonialNameRight: {
    color: theme.palette.secondary.light,
    fontWeight: 500,
    fontSize: "1.5rem",
    marginRight: "4rem",
    paddingTop: ".5rem",
    textAlign: "right",
    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
    },
  },
  altProjectTxtCtr: {
    paddingRight: "3.75rem",
    [theme.breakpoints.down("lg")]: {
      paddingRight: "3rem",
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: "0rem",
    },
  },
  testimonialBtnLeft: {
    float: "left",
    display: "block",
    marginLeft: "1.05rem",
    border: "none",
    letterSpacing: 1.25,
    fontWeight: 600,
    "&:hover": {
      textDecoration: "underline",
      backgroundColor: 'transparent',
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: '-.85rem'
    }
  },
  testimonialBtnRight: {
    float: "right",
    marginRight: "3rem",
    display: "block",
    border: "none",
    letterSpacing: 1.25,
    fontWeight: 600,
    "&:hover": {
      textDecoration: "underline",
      backgroundColor: 'transparent',
    },
    [theme.breakpoints.down('xs')]: {
      marginRight: '-.85rem',
    }
  },
  projectBtn: {
    backgroundColor: theme.palette.secondary.main,
    color: "#FFFFFF",
    fontFamily: "Montserrat, Helvetica Neue",
    fontSize: "1.25rem",
    marginRight: "auto",
    marginTop: "10%",
    transition: "0.5s",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.muted,
      boxShadow: "0 12px 24px 0 rgb(0 0 0 / 14%)",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "2%",
      marginLeft: "auto",
      marginRight: "auto",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "10%",
    },
  },
  altProjectBtn: {
    backgroundColor: theme.palette.secondary.main,
    color: "#FFFFFF",
    fontFamily: "Montserrat, Helvetica Neue",
    fontSize: "1.25rem",
    marginLeft: "auto",
    marginTop: "10%",
    transition: "0.5s",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.muted,
      boxShadow: "0 12px 24px 0 rgb(0 0 0 / 14%)",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "2%",
      marginLeft: "auto",
      marginRight: "auto",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "10%",
    },
  },
  projectDivider: {
    opacity: 1,
    maxWidth: "70%",
    marginBottom: "5rem",
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.only("lg")]: {
      maxWidth: "90%",
    },
    [theme.breakpoints.only("md")]: {
      maxWidth: "75%",
      marginTop: "2em",
      marginBottom: 0,
    },
    [theme.breakpoints.only("sm")]: {
      marginTop: "3em",
      marginBottom: 0,
      maxWidth: "95%",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
      marginTop: "3em",
      marginBottom: 0,
    },
  },
  projectAboutMargin: {
    marginTop: "25%",
    [theme.breakpoints.down("lg")]: {
      marginTop: "30%",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: 0,
    },
  },
}))


export default function ProjectsIndex() {
  // const theme = useTheme()
  const classes = useStyles()
  const {
    allContentfulCustomerTestimonialPage: { nodes: projects },
  } = useStaticQuery(query)
  // const matchesMD = useMediaQuery(theme.breakpoints.down("md"))
  return (
    <Grid container className={classes.ctr} justifyContent="center">
      <Grid item xs={12}>
        <Typography
          variant="h1"
          align="center"
          className={classes.h1}
          gutterBottom
        >
          Testimonials
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider className={classes.hr} />
      </Grid>
      <Grid item xs={12}>
        <Grid item container direction="row" justifyContent="center">
          <Container maxWidth='lg'>
            {projects.map((project, i) => {
              const projectLink = project.separatePage ? true : false
              const image = getImage(project.projectImage)
              const slug = slugify(project.url, { lower: true })
              const alt = i % 2 ? true : false

              return (
                <React.Fragment key={project.id}>
                  <Grid item xs={12}>
                    <Grid
                      container
                      item
                      className={classes.reviewLink}
                      direction={alt ? "row-reverse" : "row"}
                      xs={12}
                    >
                      <Grid item xs={12} md={8}>
                        <Grid
                          container
                          alignItems="center"
                          style={{ paddingTop: "1.5rem" }}
                          justifyContent={"flex-end"}
                          // justifyContent={alt ? "flex-end" : "flex-start"}
                        >
                          <Grid item xs={12}>
                            <Typography
                              variant="h2"
                              align={alt ? "right" : "left"}
                              className={classes.projectTitle}
                              paragraph
                              // component={Link}
                              // to={`/testimonials/${slug}`}
                              style={{ float: alt ? "right" : "left" }}
                            >
                              {project.shortDescription}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography
                              variant="h5"
                              paragraph
                              align={alt ? "right" : "left"}
                              className={
                                alt
                                  ? classes.testimonialNameRight
                                  : classes.testimonialNameLeft
                              }
                            >
                              {project.title}
                            </Typography>
                            <Button
                              variant="outlined"
                              className={
                                alt
                                  ? classes.testimonialBtnRight
                                  : classes.testimonialBtnLeft
                              }
                              component={Link}
                              to={`/testimonials/${slug}/`}
                            >
                              Read More
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <GatsbyImage
                          image={image}
                          className={
                            alt
                              ? classes.altProjectImageCtr
                              : classes.projectImageCtr
                          }
                          alt={project.title}
                          onClick={() => navigate(`/testimonials/${slug}`)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider className={classes.projectDivider} />
                </React.Fragment>
              )
            })}
            </Container>
        </Grid>
      </Grid>
    </Grid>
  )
}
